import { portalRequestor } from '../../../infrastructure/api/v1/requestors'
import { camelCaseToUnderscores } from '../../../utils/helpers'
import axios from 'axios'
import {
  fakeDataFetch,
  fakeFetch
} from '../../../infrastructure/service/tests/stubs'

const BASE_URL = process.env.REACT_APP_URL

export const updateOrderAddress = async ({
  type: addressType,
  orderID,
  addressObj
}) => {
  const url = `orders/${orderID}/update/${addressType}`
  const fetchParams = { [`${addressType}_data` + '']: addressObj }
  const result = await portalRequestor({
    type: 'post',
    url,
    fetchParams,
    caller: 'updateOrderAddress'
  })
  console.log('updateOrderAddress result', result)
  if (result.error)
    throw {
      error: true,
      message: result.errorBody.data.error,
      status: result.errorBody.status
    }
  return result
}
export const updateOrderAddressField = async ({
  type,
  orderID,
  fieldName,
  fieldValue
}) => {
  const url = `orders/${orderID}/update/${type}`
  const fetchParams = {
    [`${type}_data` + '']: { [fieldName]: fieldValue }
  }
  return await portalRequestor({
    type: 'post',
    url,
    fetchParams,
    caller: 'updateOrderAddressField'
  })
}

export const updateOrderNote = async ({ orderID, note }) => {
  const url = `orders/${orderID}/update/note`
  const fetchParams = { orders_data: { note: note } }
  return await portalRequestor({
    type: 'post',
    url,
    fetchParams
  })
}

export const uploadOrders = ({ token, formData }) => {
  console.log('uploadOrders formData', formData)
  return axios({
    url: `${BASE_URL}/portal/v1/orders/upload`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: formData,
    validateStatus: (status) => {
      return true
    }
  })
}

// function to GET orders that match all filters / parameters
export const fetchUsingAllFilters2 = ({ page = 1, filters }) => {
  const params = Object.entries(filters)
    .filter(([, value]) => typeof value !== 'undefined' && value)
    .map(([filter, value]) => {
      return `${camelCaseToUnderscores(filter)}=${value}`
    })

  const paramsStr = [`page=${page}`, ...params].join('&')
  const url = `orders?${paramsStr}`
  return portalRequestor({ url, type: 'get', caller: 'fetchUsingAllFilters2' })
}

// function to get orders placed by a single email / customer
export const fetchCustomerOrderCount = ({ customerEmail }) => {
  // const fullURL = `orders?customeremail=${customerEmail}`
  const fullURL = `customer/countOrders?email=${customerEmail}`
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchCustomerOrderCount'
  })
}
// function to fetch the next pagination of orders from API
// if no page param is named, function will default to the first page or orders
export const fetchPageOfOrders = ({
  pageNum = 1,
  startDate = '',
  endDate = ''
} = {}) => {
  console.log('fetch page of orders')
  let startDateConcat = ''
  let endDateConcat = ''

  // if a startDate is named...
  if (startDate !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    startDateConcat = `=${new Date(startDate).toISOString()}`
  }
  // if an endDate is named...
  if (endDate !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    endDateConcat = `=${new Date(endDate).toISOString()}`
  }
  const url = `orders?page=${pageNum}&start_date${startDateConcat}&end_date${endDateConcat}`
  // thanks to the interpolations above, the request BASE_URL below will work
  // WITH OR WITHOUT date parameters

  return portalRequestor({
    url: url,
    type: 'get',
    caller: 'fetchPageOfOrders'
  })
}

export const fetchOrderGridFields = () => {
  const url = `order/grid/fields`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchOrderGridFields'
  })
}
// function to GET orders that match all filters / parameters

export const fetchUsingAllFilters = ({
  startDate,
  endDate,
  purchasedFrom: storeName,
  orderItemStatus,
  orderStatus,
  orderPortalStatus,
  vendor,
  pageNum = 1,
  orderNum,
  sortOrder: orderBy = 'desc',
  product,
  customerName,
  personalized,
  multisearch,
  shipping_import_error,
  shipping_export_error
}) => {
  console.log('sorting by', orderBy)
  if (pageNum !== '') {
    // append '=' to it so that it can be interpolated in   the request BASE_URL
    pageNum = `=${pageNum}`
  }
  if (storeName !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    storeName = `&storename=${storeName}`
  }
  // append '=' to it so that it can be interpolated in the request BASE_URL
  startDate = startDate ? `&start_date=${startDate}` : ''
  // if an endDate is named...
  // append '=' to it so that it can be interpolated in the request BASE_URL
  endDate = endDate ? `&end_date=${endDate}` : ''
  // if an endDate is named...
  if (orderNum !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderNum = `&ordernum=${orderNum}`
  }
  if (orderItemStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderItemStatus = `&order_item_status=${orderItemStatus}`
  }
  if (orderStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderStatus = `&status=${orderStatus}`
  }
  if (orderPortalStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderPortalStatus = `&portal_status=${orderPortalStatus}`
  }
  // if an endDate is named...
  if (vendor !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    vendor = `&vendor=${vendor}`
  }
  if (product !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    product = `&product=${product}`
  }
  if (customerName !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    customerName = `&customer=${customerName}`
  }
  if (orderBy !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderBy = `&orderby=${orderBy}`
  }
  if (personalized !== false && personalized !== undefined) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    personalized = `&personalized=${personalized}`
  } else {
    personalized = ''
  }
  if (shipping_import_error) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    shipping_import_error = `&vendor_orders_shipment_import_status=${shipping_import_error}`
  } else {
    shipping_import_error = ''
  }
  if (shipping_export_error) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    shipping_export_error = `&shipment_status=${shipping_export_error}`
  } else {
    shipping_export_error = ''
  }
  const multisearchParam = (multisearch && `&multi_search=${multisearch}`) || ''
  const fullURL = `orders?page${pageNum}${storeName}${startDate}${endDate}${orderNum}${orderItemStatus}${orderStatus}${orderPortalStatus}${vendor}${product}${customerName}${orderBy}${personalized}${shipping_import_error}${shipping_export_error}${multisearchParam}`

  console.log('fetchUsingAllFilters', fullURL)
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchUsingAllFilters'
  })
}

export const fetchCountUsingAllFilters = ({
  startDate,
  endDate,
  purchasedFrom: storeName,
  orderItemStatus,
  orderStatus,
  orderPortalStatus,
  vendor,
  orderNum,
  sortOrder: orderBy = 'desc',
  product,
  customerName,
  personalized,
  multisearch,
  shipping_import_error,
  shipping_export_error
}) => {
  console.log('sorting by', orderBy)
  if (storeName !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    storeName = `&storename=${storeName}`
  }
  // append '=' to it so that it can be interpolated in the request BASE_URL
  startDate = startDate ? `&start_date=${startDate}` : ''
  // if an endDate is named...
  // append '=' to it so that it can be interpolated in the request BASE_URL
  endDate = endDate ? `&end_date=${endDate}` : ''
  // if an endDate is named...
  if (orderNum !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderNum = `&ordernum=${orderNum}`
  }
  if (orderItemStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderItemStatus = `&order_item_status=${orderItemStatus}`
  }
  if (orderStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderStatus = `&status=${orderStatus}`
  }
  if (orderPortalStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderPortalStatus = `&portal_status=${orderPortalStatus}`
  }
  // if an endDate is named...
  if (vendor !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    vendor = `&vendor=${vendor}`
  }
  if (product !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    product = `&product=${product}`
  }
  if (customerName !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    customerName = `&customer=${customerName}`
  }
  if (orderBy !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderBy = `&orderby=${orderBy}`
  }
  if (personalized !== false && personalized !== undefined) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    personalized = `&personalized=${personalized}`
  } else {
    personalized = ''
  }
  if (shipping_import_error) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    shipping_import_error = `&vendor_orders_shipment_import_status=${shipping_import_error}`
  } else {
    shipping_import_error = ''
  }
  if (shipping_export_error) {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    shipping_export_error = `&shipment_status=${shipping_export_error}`
  } else {
    shipping_export_error = ''
  }
  const multisearchParam = (multisearch && `&multi_search=${multisearch}`) || ''
  const fullURL = `orders/count?${storeName}${startDate}${endDate}${orderNum}${orderItemStatus}${orderStatus}${orderPortalStatus}${vendor}${product}${customerName}${orderBy}${personalized}${shipping_import_error}${shipping_export_error}${multisearchParam}`

  console.log('fetchCountUsingAllFilters', fullURL)
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchCountUsingAllFilters'
  })
}


// fetch based on order status, start/end date, and pagination limit
// (mostly used on dashboard tabs)
export const fetchUsingStatusDateProductAndPaginationLimit = ({
  startDate,
  endDate,
  orderStatus,
  orderPortalStatus = '',
  product,
  perPage
}) => {
  if (orderStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderStatus = `&status=${orderStatus}`
  }
  if (startDate !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    startDate = `&start_date=${startDate}`
  }
  // if an endDate is named...
  if (endDate !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    endDate = `&end_date=${endDate}`
  }
  if (orderPortalStatus !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    orderPortalStatus = `&portal_status=${orderPortalStatus}`
  }
  if (product !== '') {
    // append '=' to it so that it can be interpolated in the request BASE_URL
    product = `&product=${product}`
  }
  // if (perPage !== '') {
  //   // append '=' to it so that it can be interpolated in the request
  // BASE_URL perPage = `=${perPage}` }

  const fullURL = `orders?${orderStatus}${startDate}${endDate}${orderPortalStatus}${product}`

  console.log(fullURL)

  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchUsingStatusDateProductAndPaginationLimit'
  })
}

export const resendOrderItems = async ({ orderId = '', items = [] }) => {
  const fetchParams = { order_items: items }

  const url = `orders/${orderId}/resend`
  return await portalRequestor({
    type: 'post',
    fetchParams,
    url,
    caller: 'fetchMarketplaces'
  })
}

// function to GET a single order
export const fetchSingleOrder = ({ orderID }) => {
  return portalRequestor({
    url: `orders/${orderID}`,
    type: 'get',
    caller: 'fetchSingleOrder'
  })
}

// function to GET a timeline of actions that are taken with the order
export const fetchOrderTimeline = ({ orderID }) => {
  return portalRequestor({
    url: `order/${orderID}/timeline`,
    type: 'get',
    caller: 'fetchOrderTimeline'
  })
}

// function to put a single order on hold
export const holdOrder = ({ orderID }) => {
  return portalRequestor({
    url: `order/${orderID}/hold`,
    type: 'post',
    caller: 'holdOrder'
  })
}

// function to take a single order OFF hold
export const unHoldOrder = ({ orderID }) => {
  return portalRequestor({
    url: `order/${orderID}/unhold`,
    type: 'post',
    caller: 'holdOrder'
  })
}

// function to re-scan order to correct errors, update product info etc
export const revalidateOrder = ({ orderID }) => {
  const fullURL = `order/${orderID}/validate?`
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'revalidateOrder'
  })
}

// function to cancel a single order
export const cancelOrder = ({ orderID }) => {
  return portalRequestor({
    url: `order/${orderID}/cancel`,
    type: 'get',
    caller: 'cancelOrder'
  })
}

export const fetchVendorCarriers = ({ vendorCode }) => {
  const url = `carriers/vendor/${vendorCode}`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchVendorCarriers'
  })
}

export const fetchMarketplaceCarriers = ({ marketplaceId }) => {
  const url = `carriers/marketplace/${marketplaceId}`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchMarketplaceCarriers'
  })
}
export const fetchVendorOrderCarriers = ({ vendorOrderId }) => {
  const url = `carriers/vendorOrder/${vendorOrderId}`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchVendorOrderCarriers'
  })
}
export const fetchOrderVendorCarriers = ({ orderVendorId }) => {
  const url = `carriers/orderVendor/${orderVendorId}`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchOrderVendorCarriers'
  })
}
//
// export const fetchVendorOrderCarriers = ({ vendorOrderId }) => {
//   const url = `carriers/vendorOrder/${vendorOrderId}`
//   return portalRequestor({
//                            type: 'get',
//                            url,
//                            caller: 'fetchVendorOrderCarriers'
//                          })
// }
export const saveTrackingNumber = ({
  vendor_order_id,
  carrier,
  tracking,
  order_vendor_id,
  order_item_ids,
  vendor_order_number = null,
  shipping_at = null
}) => {
  const url = `shipment/create`
  const fetchParams = {
    vendor_order_id,
    carrier,
    tracking,
    order_vendor_id,
    vendor_order_number,
    shipping_at,
    order_item_ids
  }
  console.log('Save Tracking Number params', fetchParams)
  return portalRequestor({
    type: 'post',
    fetchParams,
    url,
    caller: 'saveTrackingNumber'
  })
}

export const createVendorOrderApi = ({
  order_vendor_id,
  order_item_ids,
  // sent_order_number,
  vendor_order_number
}) => {
  const url = `vendorOrders/create`
  const fetchParams = {
    order_item_ids,
    order_vendor_id,
    vendor_order_number
    // sent_order_number
  }
  return portalRequestor({
    type: 'post',
    fetchParams,
    url,
    caller: 'createVendorOrderApi'
  })
}
