import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useSetRecoilState, useResetRecoilState } from 'recoil'
import {
  SnowGrid,
  SnowPaper,
  SnowTable,
  SnowTableHead,
  SnowTableBody,
  SnowTableRow,
  SnowTableCell,
  SnowTab,
  SnowTabs
} from '../../../../shared/components/snowComponents'
import { DateRangePicker } from 'react-dates'
import OrderRow from './OrderRow'
import ColumnTitles from './ColumnTitles'
import OrderPortalStatusDropdown from './Filters/OrderPortalStatusDropdown'
import VendorFilter from './Filters/VendorFilter'
import PaymentFilter from './Filters/PaymentFilter'
import CustomerFilter from './Filters/CustomerFilter'
import ProductFilter from './Filters/ProductFilter'
import StoreFilter from './Filters/StoreFilter'
import OrderNumberFilter from './Filters/OrderNumberFilter'
import { fetchUsingAllFilters, fetchCountUsingAllFilters } from '../../infrastructure/fetches'
import OrderItemStatusFilter from './Filters/OrderItemStatusFilter'
import {
  setScPageHeaderTitleCmd,
  useScPageHeaderTitle
} from '../../../app/data/currentHeadingState'
import { statusFilterTabsJson } from './statusFilterTabsJson'
import { getFilterConfigs, useInfiniteScroll } from './utils'
import ScSpinner from '../../../../shared/components/ScSpinner/ScSpinner'
import HubActions from './HubActions'
import { searcherState } from '../../../../shared/components/searcher/data/searcherState'
import { getNamedRangeDates } from './DateRangeScoper'
import styled from 'styled-components'
import {
  filterByPaymentMethod,
  filterByVendorStatus
} from '../../infrastructure/functions'

const OrdersTabsListView = styled(SnowTabs)`
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.mainPalette.grey.light};
`

const OrdersTabView = styled(SnowTab)`
  max-width: auto;
  position: relative;
  ::before {
    content: '';
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.mainPalette.grey[100]};
    bottom: 0;
    transition: opacity ${({ theme }) => theme.transition.main};
  }
  :hover::before {
    opacity: 1;
  }
  &.MuiTab-root {
    ${({ theme }) => `
      padding: ${theme.spacing.secondary};
      font-size: ${theme.fontSize.middle};
      font-weight: ${theme.fontWeight.thin};
      color: ${theme.mainPalette.typography.main};
      &[aria-selected='true']{
        color: ${theme.mainPalette.typography.main};
      }
    `}
    min-width: fit-content;
    text-transform: capitalize;
    @media (min-width: 600px) {
      min-width: fit-content;
    }
  }
  &.MuiTab-textColorInherit {
    color: inherit;
    opacity: 1;
  }
`

const OrdersPaperView = styled(SnowPaper)`
  padding: ${({ theme }) => theme.spacing.secondary};
`

const OrdersFilterRowView = styled(SnowTableRow)`
  min-height: 38px;
  box-sizing: border-box;
`

const OrdersFilterCellView = styled(SnowTableCell).attrs({
  padding: 'none'
})`
  &.MuiTableCell-root {
    width: min-content;
    min-width: 100px;
    box-sizing: border-box;
    border: ${({ theme }) => theme.border.main};
    input,
    select {
      border: none;
    }
  }
`

const OrderDateFilterView = styled.div`
  max-width: 250px;
  width: 100%;
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    padding: 0 1rem;
    display: flex;
    min-height: 40px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .DateInput {
    flex: 1 1 auto;
    min-width: 75px;
    min-height: 38px;
    ${({ theme }) => `
      color: ${theme.mainPalette.typography.light};
      font-size: ${theme.fontSize.main};
      font-weight: ${theme.fontWeight.main};
    `}
    .DateInput_input {
      padding: 6px 5px 6px;
      width: 100%;
      min-height: 38px;
      box-sizing: border-box;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      text-align: center;
      ::placeholder {
        color: inherit;
        font-size: inherit;
      }
    }
  }
  .DateRangePickerInput_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      flex: 0 0 14px;
    }
  }
`

const moment = require('moment')

/**
 * HeadingSet is th Component to 'trick' hook absent in OrderHubTable
 * to set up Page header
 * @param heading
 * @param subtitle
 * @returns {null}
 */
const HeadingSet = ({ heading }) => {
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  useEffect(() => {
    setHeading(heading)
  }, [])
  return null
}
const defaultDateRange = '1month'
const [startDate, endDate] = getNamedRangeDates(defaultDateRange)
const filterDefaults = {
  orderNum: '',
  vendor: '',
  vendorStatus: '',
  orderItemStatus: '',
  orderStatus: '',
  orderPortalStatus: '',
  purchasedFrom: '',
  product: '',
  customerName: '',
  paymentMethod: '',
  grandTotal: '',
  startDate: startDate ? startDate.toISOString() : '',
  endDate: endDate ? endDate.toISOString() : '',
  personalized: false
}

const OrderHubTable = (props) => {
  const location = useLocation()
  const [, setPageHeader] = useScPageHeaderTitle()
  const [searchParams, setSearchParams] = useSearchParams()
  const resetSearchStr = useResetRecoilState(searcherState)
  const [dateRangeName, setDateRangeName] = useState(defaultDateRange)
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortBy, setSortBy] = useState('desc')
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isReLoading, setIsReLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [canBeMorePages, setCanBeMorePages] = useState(true)
  const [totalOrderCount, setTotalOrderCount] = useState('N/A')
  const [loadedData, setLoadedData] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [focusedInput, setFocusedInput] = useState(null)
  const [tab, setTab] = useState(0)

  // function requests the next page of orders
  const loadNextPageOfOrders = () => {
    // should only retrieve pages 2 or more
    if (currentPage === 1) {
      return
    }
    setIsLoading(true)
    console.log('LoadNextPageOfOrders')
    fetchUsingAllFilters({
      ...filters,
      sortOrder,
      pageNum: currentPage
    }).then((response) => {
      console.log('fetchUsingAllFilters', response)
      if (response.data) {
        const { data } = response
        // if the orders array is less than the pagination limit (usually 50)...
        // ...then set canBeMorePages to 'true' so that the infinite scroll will not keep requesting pages
        updateHasMorePages(response)

        setCurrentPage(currentPage + 1)
        // add new orders strorages
        setLoadedData([...loadedData, ...data])
        setDisplayedData([...displayedData, ...data])
      }
    })
    setIsLoading(false)
  }

  const [lastElementRef] = useInfiniteScroll(
    canBeMorePages ? loadNextPageOfOrders : () => { },
    isLoading
  )
  const statusFilterTabs = statusFilterTabsJson

  const filtersConfigMap = getFilterConfigs(filters, sortOrder)

  const getInitialFilters = () => {
    const initialFilterConfigs = getFilterConfigs(filterDefaults, sortOrder)
    console.log('initialFilterConfigs', initialFilterConfigs, filterDefaults)
    const updatedFilters = Object.entries(filterDefaults)
      .map(([key, value]) => {
        const config = initialFilterConfigs[key]
        // console.log('initialFilterConfigs map', key, value)
        return [
          key,
          searchParams.get(config.param)
            ? searchParams.get(config.param)
            : value
        ]
      })
      .reduce((acc, [key, value]) => {
        acc[key] = initialFilterConfigs[key].castParam(value) //value
        return acc
      }, {})
    // console.log('initialFilterConfigs updatedFilters', updatedFilters)
    return updatedFilters
  }

  // function to set total order count, which displays at top of order grid, above table
  const updateTotalOrderCount = (apiResponse) => {
    if (apiResponse.meta && apiResponse.meta.total) {
      const rawTotalOrderCount = apiResponse.meta.total
      // format with commas
      setTotalOrderCount(
        rawTotalOrderCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      )
    } else {
      setTotalOrderCount('...')
      // Request count from API
      fetchCountUsingAllFilters({
        ...filters,
        sortOrder
      }).catch((e) => {
        console.error(e.message)
        setTotalOrderCount('N/A')
      }).then((response) => {
        console.log('fetchCountUsingAllFilters', response)
        if (typeof response.data.count !== 'undefined') {
          setTotalOrderCount(response.data.count)
        } else {
          setTotalOrderCount('N/A')
	}
      })
    }
  }

  // if the orders array is less than the pagination limit (usually 50)...
  // ...then set canBeMorePages to 'true' so that the infinite scroll will not keep requesting pages
  const updateHasMorePages = (apiResponse) => {
    const { per_page, from, to } = apiResponse?.meta ?? {}
    const loadedCount = 1 + to - from
    const canBeNextPage = Number(per_page) === Number(loadedCount)
    console.log('updateHasMorePages', {
      canBeNextPage,
      loadedCount,
      per_page,
      from,
      to
    })
    setCanBeMorePages(canBeNextPage)
    // if (apiResponse.meta && apiResponse.meta.total) {
    //   const rawTotalOrderCount = apiResponse.meta.total
    //   // format with commas
    //   setTotalOrderCount(
    //     rawTotalOrderCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //   )
    // }
  }

  // function to run all filters async filters
  // this includes filters for: product name, customer name, payment method, grand total, vendor status, and asc / desc order
  const runNonAsyncFilters = (data) => {
    let filteredData = data

    if (filters.paymentMethod) {
      filteredData = filterByPaymentMethod(filteredData, filters.paymentMethod)
    }

    if (filters.vendorStatus) {
      filteredData = filterByVendorStatus(filteredData, filters.vendorStatus)
    }

    filteredData = filteredData.sort()
    return filteredData
  }

  // runs API / async filters: startDate, endDate, store, order number, order status, vendor name, customer name
  const getFilteredData = () => {
    // request orders using all order params/filters
    setIsReLoading(true)
    // console.log('getFilteredData')
    fetchUsingAllFilters({
      ...filters,
      sortOrder,
      pageNum: 1
    }).then((response) => {
      setIsReLoading(false)
      console.log('getFilteredData ', response)
      if (response.data) {
        const { data } = response
        updateHasMorePages(response)
        updateTotalOrderCount(response)
        const filteredData = data && runNonAsyncFilters(data)

        setLoadedData([...loadedData, ...data])

        // add new orders strorages
        setDisplayedData([...filteredData])
        setCurrentPage(2)
      }
    })
  }

  // function to update URL parameters to reflect current grid filters
  // filters to url
  const updateURLSearchParams = () => {
    const sortAndFilters = { ...filters, sortOrder: sortOrder }
    console.log('updateURLparameters,filtersConfigMap', {
      filters,
      filtersConfigMap
    })
    const appliedFilters = Object.entries(sortAndFilters)
      .filter(([key, value]) => value !== filterDefaults[key])
      .reduce((accum, [key, value]) => {
        const config = filtersConfigMap[key]
        if (config) {
          accum[config.param] = config.get() //value
        } else {
          accum[key] = value
        }

        return accum
      }, {})

    // navigate(`${baseURL}${urlParamString}`)
    console.log('updateURLSearchParams', appliedFilters)
    setSearchParams(appliedFilters)
    // then refresh state based on new URL updateURLparameters
    // checkForURLparameters(`${baseURL}${urlParamString}`)
  }

  const resetFiltersTo = (filterList) => {
    setFilters(filterList)
    console.log('filterList', filterList)
    setCurrentPage(1)
    setCanBeMorePages(true)
  }
  // function to set user input filters for all grid filters (except date filters)
  const setFilter = (event) => {
    resetFiltersTo({ ...filters, [event.target.name]: event.target.value })
  }
  const setFilterPair = (code, value) => {
    console.log('setFilterPair', code, value)
    resetFiltersTo({ ...filters, [code]: value })
  }

  const setStatusFilter = (filter) => {
    resetFiltersTo({
      ...filters,
      ...filter
    })
  }
  const setDateFilters = (startDate, endDate) => {
    // console.log('set date filters', startDate, endDate)
    resetFiltersTo({
      ...filters,
      ...{
        startDate: startDate ? startDate.toISOString() : '',
        endDate: endDate ? endDate.toISOString() : ''
      }
    })
  }

  const handleChange = (event, newValue) => {
    setStatusFilter(statusFilterTabs[newValue]?.filter)
    setTab(newValue)
  }

  // toggle sorting by ascending or descending order
  const toggleDateFilter = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }
  const onDateRangeUpdate = ([startDate, endDate], type) => {
    // console.log('startDate, endDate,dateRangeName', startDate, endDate, type)
    if (typeof startDate === 'undefined' && typeof endDate === 'undefined')
      return
    setDateRangeName(type)

    setDateFilters(startDate, endDate)
  }

  // function to clear all order filters / data filters
  const clearAllFilters = () => {
    // setDisplayedData(loadedData)
    console.log('ordertable defaultDateRange', defaultDateRange)

    setDateRangeName(defaultDateRange)
    setSortOrder(true)
    resetSearchStr()

    // const initialFilters = getInitialFilters()
    // console.log('filterDefaults', filterDefaults, initialFilters, loadedData)
    resetFiltersTo(filterDefaults)
  }

  const getOrderDropdownValue = () => {
    if (filters.orderPortalStatus === '') {
      return 'Status'
    }
    return filters.orderPortalStatus
  }

  const isStatusTabActive = (tab = 'all') => {
    const orderStatus = filters.orderStatus
    const personalizedStatus = filters.personalized
    const shipping_import_error = filters.shipping_import_error
    const shipping_export_error = filters.shipping_export_error

    if (tab === 'personalized') {
      if (personalizedStatus === true && orderStatus == '') {
        return 'active'
      }
      return ''
    } else if (tab === 'all') {
      if (
        orderStatus === '' &&
        personalizedStatus === false &&
        !shipping_import_error &&
        !shipping_export_error
      ) {
        return 'active'
      }
      return ''
    }
    if (orderStatus === tab) {
      return 'active'
    }

    if (tab === 'shipping_import_error' && shipping_import_error) {
      return 'active'
    }

    if (tab === 'shipping_export_error' && shipping_export_error) {
      return 'active'
    }

    return ''
  }

  const isFilterActive = (filterName) => {
    return filters[filterName] ? 'active' : ''
  }
  useEffect(() => {
    // set event listener to detect when user has scrolls 80% down the window
    // this will trigger loading more orders

    // if a order grid url has been saved, delete it
    localStorage.removeItem('url')
    setPageHeader('Orders Hub')
    // check for url parameters / grid filters
    // checkForURLparameters()
    // if URL changes, check for URL parameters
  }, [])

  useEffect(() => {
    console.log('OrderHubTable', loadedData)
  }, [loadedData])

  useEffect(() => {
    const initialFilters = getInitialFilters()
    setFilters(initialFilters)
  }, [location.pathname])

  useEffect(() => {
    console.log('useEffect filters', filters)
    if (Object.entries(filters).length) {
      updateURLSearchParams()
      getFilteredData()
    }
  }, [filters, sortOrder])

  useEffect(() => {
    console.log('OrdersTable2 tab', {
      statusFilterTabs: statusFilterTabs[tab]?.filter,
      tab
    })
  }, [tab])

  const endDate = filters.endDate ? moment(filters.endDate) : null
  const startDate = filters.startDate ? moment(filters.startDate) : null
  const filtersReady = !!Object.keys(filters).length

  const filterFields = [
    {
      field: (
        <OrderNumberFilter
          selectValue={filters.orderNum}
          setFilter={setFilterPair}
          isFilterActive={isFilterActive('orderNum')}
          tooltip={'Type order number'}
        />
      )
    },
    {
      field: (
        <StoreFilter
          selectValue={filters.purchasedFrom}
          setFilter={setFilterPair}
          isFilterActive={isFilterActive('purchasedFrom')}
          tooltip={'Type store'}
        />
      )
    },
    {
      field: (
        <OrderDateFilterView aria-label="date-filter">
          <DateRangePicker
            isOutsideRange={() => false}
            startDateId="startDate"
            endDateId="endDate"
            startDatePlaceholderText="Start"
            endDatePlaceholderText="End"
            startDate={startDate}
            endDate={endDate}
            onDatesChange={({ startDate, endDate }) => {
              setDateRangeName('arbitrary')
              console.log('DateRangePicker onDatesChange', startDate, endDate)
              return setDateFilters(startDate, endDate)
            }}
            focusedInput={focusedInput}
            displayFormat={() => 'MM/DD/YY'}
            // showClearDates={true}
            onFocusChange={(focusedInput) => {
              // console.log()
              setFocusedInput(focusedInput)
            }}
          />
        </OrderDateFilterView>
      )
    },
    {
      field: (
        <ProductFilter
          selectValue={filters.product}
          setFilter={setFilterPair}
          isFilterActive={isFilterActive('product')}
          tooltip={'Type product title'}
        />
      )
    },
    {
      field: (
        <OrderItemStatusFilter
          setFilter={setFilter}
          selectValue={filters.orderItemStatus}
        />
      )
    },
    {
      field: (
        <CustomerFilter
          selectValue={filters.customerName}
          setFilter={setFilterPair}
          isFilterActive={isFilterActive('customerName')}
          tooltip={'Type customer name'}
        />
      )
    },
    {
      field: (
        <PaymentFilter
          selectValue={filters.paymentMethod}
          setFilter={setFilter}
        />
      )
    },
    {
      field: (
        <VendorFilter
          setFilter={setFilterPair}
          selectValue={filters.vendor}
          isFilterActive={isFilterActive('vendor')}
          tooltip={'Type vendor'}
        />
      )
    },
    {
      field: (
        <OrderPortalStatusDropdown
          setFilter={setFilter}
          selectValue={getOrderDropdownValue()}
        />
      )
    }
  ]
  return (
    <>
      {/*<HeadingSet heading={'Orders Hub'} />*/}
      <HubActions
        clearAllFilters={clearAllFilters}
        doSearch={(searchfor) => {
          resetFiltersTo({ ...filters, multisearch: searchfor })
        }}
        totalOrderCount={totalOrderCount}
        onDateRangeUpdate={onDateRangeUpdate}
        dateRangeName={dateRangeName}
      />

      <OrdersPaperView>
        <OrdersTabsListView
          value={tab}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="filter-tabs"
        >
          {console.log('OrdersTable2', statusFilterTabs)}
          {statusFilterTabs?.length
            ? statusFilterTabs.map((item) => (
              <OrdersTabView key={item.code} label={item.label} />
            ))
            : null}
        </OrdersTabsListView>
        {isReLoading ? (
          <SnowGrid alignItems={'center'} container justifyContent={'center'}>
            <ScSpinner />
          </SnowGrid>
        ) : (
          <>
            <SnowTable>
              <SnowTableHead>
                {filtersReady && (
                  <OrdersFilterRowView>
                    {filterFields?.length
                      ? filterFields.map(({ field }, index) => (
                        <OrdersFilterCellView key={index}>
                          {field}
                        </OrdersFilterCellView>
                      ))
                      : null}
                  </OrdersFilterRowView>
                )}
                <ColumnTitles toggleDateFilter={toggleDateFilter} />
              </SnowTableHead>
              {!!displayedData.length && (
                <SnowTableBody id="tbody">
                  {displayedData.map((row) => (
                    <OrderRow row={row} key={row.id} />
                  ))}
                </SnowTableBody>
              )}
            </SnowTable>
            <div ref={lastElementRef}> </div>
            <SnowGrid alignItems={'center'} container justifyContent={'center'}>
              {isLoading && <ScSpinner />}
            </SnowGrid>
          </>
        )}
      </OrdersPaperView>
    </>
  )
}
export default OrderHubTable
